function Footer() {
  return (
    <footer className="footer_section">
      <p>
        &copy;
        {' '}
        <span id="displayYear">{(new Date().getFullYear())}</span>
        {' '}
        All Rights Reserved By &nbsp;
        <a href="https://linkedin.com/alopgudhate">Surya Techsoft</a>
      </p>
    </footer>
  );
}

export default Footer;
