/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

function Services({ serviceConfig }) {
  return (
    <section className="service_section">
      <div>
        <div className="heading_container heading_center ">
          <h4 className="" style={{ paddingTop: '48px', color: '#00BF63', fontWeight: 'bold' }}>
            {serviceConfig.heading}
          </h4>
          <p className="col-lg-8 px-0" style={{ fontSize: '20px' }}>
            {serviceConfig.description}
          </p>
        </div>
        <div className="service_container">
          <OwlCarousel
            className="owl-theme"
            center
            autoplay
            loop
            margin={0}
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 3,
              },
              991: {
                items: 3,
              },
            }}
            items={1}
          >
            {serviceConfig?.list.map((service) => (
              <div className="item">
                <div className="box ">
                  <div className="img-box">
                    <img src={service.icon} crossOrigin="anonymous" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>
                      {service.name}
                    </h5>
                    <p>
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}

          </OwlCarousel>
        </div>

        {serviceConfig.readMore
              && (
              <div className="btn-box">
                <Link to={serviceConfig.readMore.url}>{serviceConfig.readMore.label}</Link>
              </div>
              )}
      </div>
    </section>
  );
}

export default Services;
