/* eslint-disable react/prop-types */

import { Link } from 'react-router-dom';

function ImageTextSection({ sectionConfig }) {
  return (
    <section className="about_section" style={{ marginTop: '50px' }}>
      <div className="container-fluid">
        <div className="row">
          <div className={`col-md-6 px-md-5 ${sectionConfig.textLast ? 'order-md-last' : ''}`}>
            <div className="detail-box pr-md-2">
              {(sectionConfig.heading1 || sectionConfig.heading2)
              && (
              <div className="heading_container">
                {sectionConfig.heading1 && (
                <h4 className="" style={{ color: '#00BF63', fontWeight: 'bold' }}>
                  {sectionConfig.heading1.label}
                </h4>
                )}
                {sectionConfig.heading2
                && (
                <h2 className="" style={{ paddingTop: '0px' }}>
                  {sectionConfig.heading2.label}
                </h2>
                )}
              </div>
              )}
              {sectionConfig?.texts?.map((text) => ((
                <p className="detail_p_mt" style={{ fontSize: '20px' }}>
                  {text.label}
                </p>
              )))}
              {sectionConfig?.list
              && (
              <ul>
                {sectionConfig?.list?.map((item) => <li style={{ fontSize: '20px' }}>{item.label}</li>)}
              </ul>
              )}

              {sectionConfig.readMore
              && <Link to={sectionConfig.readMore.url}>{sectionConfig.readMore.label}</Link>}
            </div>
          </div>
          <div className="col-md-6 px-0">
            <div className="img-box">
              <img src={sectionConfig.imageUrl} crossOrigin="anonymous" className="box_img" alt="about img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImageTextSection;
