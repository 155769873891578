/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

function Hero({ slidesConfig }) {
  return (
    <>
      <div>
        <img
          className="slider_section_mobile_image d-block d-md-none d-lg-none"
          src={slidesConfig?.mobileImageUrl}
          crossOrigin="anonymous"
          alt=""
        />
      </div>
      <div className="hero_area d-none d-md-block d-lg-block">
        <div className="hero_bg_box">
          <img src={slidesConfig?.imageUrl} crossOrigin="anonymous" alt="" />
        </div>

        <section className="slider_section ">
          <OwlCarousel className="owl-theme" loop items={1} autoplaySpeed={2000} autoplayTimeout={5000} autoplay>
            {slidesConfig?.slides?.map((config) => (
              <div className="item">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-10 col-md-11 mx-auto">
                      <div className="detail-box">
                        { config?.texts?.map((text) => (
                          <h1>
                            {text.label}
                          </h1>
                        ))}
                        {config?.detailsUrl && (
                        <div className="btn-box">
                          <a href="" className="btn1">
                            <Link to={config.detailsUrl.url} />
                            {config.detailsUrl.label}
                          </a>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </section>
      </div>
    </>
  );
}

export default Hero;
