import { Card } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

function Testimonials({ testimonialsConfig }) {
  return (
    <section className="client_section">
      <div>
        <div className="heading_container heading_center">
          <h4 className="" style={{ paddingTop: '48px', color: '#00BF63', fontWeight: 'bold' }}>
            {testimonialsConfig?.heading1}
          </h4>
          <h2 style={{ paddingTop: '0px' }}>
            {testimonialsConfig?.heading2}
          </h2>
        </div>
        <div className="" data-ride="carousel">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={15}
            center
            autoplay
            responsive={{
              0: {
                center: false,
                items: 1,
                margin: 0,
              },
              991: {
                center: true,
                items: 3,
              },
            }}
          >

            {testimonialsConfig?.list.map(((config) => (
              <div className="item">
                <Card>
                  <Card.Body>
                    <blockquote className="mb-0">
                      <p>
                        {config?.feedback}
                      </p>
                      <footer className="blockquote-footer">
                        <cite title="Source Title">{config?.name}</cite>
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </div>
            )))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
