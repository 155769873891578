/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import About from './About';
import ForWhom from './ForWhom';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import Services from './Services';
import Testimonials from './Testimonials';
import FeaturesAndBenefits from './FeaturesAndBenefits';
import WhyChooseUs from './WhyChooseUs';

function Home(props) {
  const { texts } = props;
  return (
    <>
      <Hero slidesConfig={texts?.hero} />
      <About sectionConfig={texts?.about} />
      <WhyChooseUs sectionConfig={texts?.whyUs} />
      <FeaturesAndBenefits sectionConfig={texts?.featuresBenefits} />
      <HowItWorks sectionConfig={texts?.howitworks} />
      <ForWhom sectionConfig={texts?.forWhom} />
      <Services serviceConfig={texts?.services} />
      <Testimonials testimonialsConfig={texts?.testimonials} />
    </>
  );
}

export default Home;
