/* eslint-disable react/prop-types */
import ImageTextSection from './ImageTextSection';

function ServicesPage({ serviceConfig }) {
  return (
    serviceConfig.list.map((config) => <ImageTextSection sectionConfig={config} />)
  );
}

export default ServicesPage;
