import axios from 'axios';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* eslint-disable react/button-has-type */
function Enquiry({ enquiryConfig }) {
  const [enquiryForm, setEnquiryForm] = useState({});

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setEnquiryForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const submitButton = (e) => {
    e.preventDefault();
    if (isValidEmail(enquiryForm.email)) {
      axios.post('/api/v1/enquiry', enquiryForm).then(() => {
        toast.success('Thank you for contacting us. We will get back to you see.', {
          position: 'top-center', autoClose: 3000, closeButton: false, icon: false,
        });
      }, () => {
        toast.error('Something went wrong. Please raise query again with correct details.');
      });
    } else {
      toast.error('Please enter valid email and raise enquiry again.', {
        position: 'top-center', autoClose: 3000, closeButton: false, icon: false,
      });
    }
  };

  return (
    <section className="contact_section ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 px-0">
            <div className="img-box card">
              <img src={enquiryConfig?.imageUrl} crossOrigin="anonymous" className="box_img" alt="img" />
            </div>
          </div>
          <div className="col-md-6 mx-auto">
            <div className="form_container">
              <div className="heading_container heading_center">
                <h4 className="" style={{ paddingTop: '48px', color: '#00BF63', fontWeight: 'bold' }}>
                  {enquiryConfig?.heading1}
                </h4>
                <h2 style={{ paddingTop: '0px' }}>{enquiryConfig?.heading2}</h2>
              </div>
              <form>
                {enquiryConfig?.fields?.map((field) => (
                  <div className="form-row">
                    <div className="form-group col">
                      <input
                        required="true"
                        className="form-control"
                        type="text"
                        name={field.name}
                        onChange={inputsHandler}
                        placeholder={field.placeholder}
                        value={enquiryForm[field.name]}
                      />
                    </div>
                  </div>
                ))}
                <ToastContainer />
                <div className="btn_box">
                  <button onClick={submitButton}>
                    {enquiryConfig?.submit?.label}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Enquiry;
