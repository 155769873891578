/* eslint-disable react/prop-types */
function HowItWorks({ sectionConfig }) {
  return (
    <section className="team_section" style={{ padding: '0 30px' }}>
      <div>
        <div className="heading_container heading_center">
          <h4 style={{ paddingTop: '48px', color: '#00BF63', fontWeight: 'bold' }}>
            {sectionConfig.heading}
          </h4>
          <p style={{ fontSize: '20px' }}>
            {sectionConfig.description}
          </p>
        </div>
        <div className="row">
          {sectionConfig?.list.map((config) => (
            <div className="col-md-4 col-sm-6 mx-auto" style={{ padding: '0 20px' }}>
              <div className="box" style={{ padding: '0px' }}>
                <div className="img-box">
                  <img src={config.imageUrl} crossOrigin="anonymous" alt="" />
                </div>
                <div className="detail-box">
                  <h3 className="">
                    {config.label}
                  </h3>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>

  );
}

export default HowItWorks;
