import ImageTextSection from './ImageTextSection';

function BlogsList() {
  const sectionConfig = [
    {
      heading1: { label: 'This is a blog 1' },
      text: { label: 'Just like the sun, we all are capable of shining like a star as well as giving light to everyone around us. Our mind has a secret power and potential. Once unleashed, we can make the world a better place for ourselves as well as our loved ones.' },
      imageUrl: 'images/about-img.jpg',
      textLast: true,
      readMore: { label: 'Read more', redirectTo: '/about' },
    },
    {
      heading1: { label: 'This is a blog 2' },
      text: { label: 'Just like the sun, we all are capable of shining like a star as well as giving light to everyone around us. Our mind has a secret power and potential. Once unleashed, we can make the world a better place for ourselves as well as our loved ones.' },
      imageUrl: 'images/about-img.jpg',
      readMore: { label: 'Read more', redirectTo: '/about' },
    },
    {
      heading1: { label: 'This is a blog 3' },
      text: { label: 'Just like the sun, we all are capable of shining like a star as well as giving light to everyone around us. Our mind has a secret power and potential. Once unleashed, we can make the world a better place for ourselves as well as our loved ones.' },
      imageUrl: 'images/about-img.jpg',
      textLast: true,
      readMore: { label: 'Read more', redirectTo: '/about' },
    },
    {
      heading1: { label: 'This is a blog 4' },
      text: { label: 'Just like the sun, we all are capable of shining like a star as well as giving light to everyone around us. Our mind has a secret power and potential. Once unleashed, we can make the world a better place for ourselves as well as our loved ones.' },
      imageUrl: 'images/about-img.jpg',
      readMore: { label: 'Read more', redirectTo: '/about' },
    },

  ];
  return (
    <>
      {sectionConfig.map((config) => <ImageTextSection sectionConfig={config} />)}
    </>
  );
}

export default BlogsList;
