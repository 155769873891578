/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable jsx-a11y/anchor-is-valid */
function Contact({ contactConfig }) {
  return (
    <section className="info_section ">
      <div className="container" style={{ margin: '0 auto' }}>
        <div className="info_top">
          <div className="row">
            {contactConfig?.contactDetails?.map((contact) => (
              <div className="col-md-5">
                <div className="info_contact">
                  <a href="">
                    <i className={`fa ${contact.icon}`} aria-hidden="true" />
                    <span>
                      {contact?.label}
                    </span>
                  </a>
                </div>
              </div>
            ))}
            <div className="col-md-2">
              {contactConfig?.socialIcons && (
              <div className="social_box">
                {contactConfig?.socialIcons.map((socialIcon) => (
                  <a href="">
                    <i className={`fa ${socialIcon.icon}`} aria-hidden="true" />
                  </a>
                ))}
              </div>
              )}
            </div>
          </div>
        </div>
        <div className="info_bottom">
          <div className="row">
            <div className="col-lg-10 col-md-6">
              <div className="info_detail">
                <h5>
                  {contactConfig?.brand?.name?.label}
                </h5>
                <p>
                  {contactConfig?.brand?.name?.tagline}
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="">
                <h5>
                  {contactConfig?.usefulLinks?.heading}
                </h5>
                <div className="row">
                  {contactConfig?.usefulLinks?.list.map((link) => (
                    <a href={link.url} target={link?.newTab ? '_balnk' : ''}>
                      {link.label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
