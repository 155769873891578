/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import {
  Nav, Navbar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header({ headerConfig }) {
  return (
    <div className="header_area">
      <header className="header_section">
        {headerConfig?.navbar1 && (
        <div className="header_top">
          <div className="container-fluid header_top_container">
            {headerConfig?.navbar1?.contactDetails && (
            <div className="contact_nav">
              {headerConfig?.navbar1?.contactDetails.map((contact) => (
                <a href="">
                  <i className={`fa ${contact.icon}`} aria-hidden="true" />
                  <span>
                    {contact.label}
                  </span>
                </a>
              ))}
            </div>
            )}
            {headerConfig?.navbar1?.socialIcons && (
            <div className="social_box">
              {headerConfig?.navbar1?.socialIcons.map((socialIcon) => (
                <a href="">
                  <i className={`fa ${socialIcon.icon}`} aria-hidden="true" />
                </a>
              ))}
            </div>
            )}
          </div>
        </div>
        )}
        {headerConfig?.navbar2 && (
          <Navbar expand="lg" className="navbar-bg-white">
            <Navbar.Brand>
              {' '}
              <Link to={headerConfig?.navbar2?.brand?.name?.url}>
                <img src={headerConfig?.navbar2?.brand?.name?.logoUrl} crossOrigin="anonymous" alt="" style={{ height: '25px', width: '160px' }} />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
            <Navbar.Collapse className="justify-content-end" id="navbarSupportedContent">
              <Nav>
                {headerConfig?.navbar2?.menus.map((menu) => (
                  <li className="nav-item active">

                    <Nav.Link href={menu.url} target={menu?.newTab ? '_balnk' : ''}>
                      {menu.label}
                    </Nav.Link>
                  </li>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}

      </header>
    </div>
  );
}

export default Header;
