/* eslint-disable jsx-a11y/anchor-is-valid */

function ForWhom({ sectionConfig }) {
  return (
    <section className="service_section" style={{ background: '#f4fff9' }}>
      <div>
        <div className="heading_container heading_center" style={{ marginBottom: '0px' }}>
          <h4 className="" style={{ paddingTop: '48px', color: '#00BF63', fontWeight: 'bold' }}>
            {sectionConfig?.heading}
          </h4>
          <p style={{ fontSize: '20px' }}>
            {sectionConfig?.description}
          </p>
        </div>
        <div className="service_container row align-items-center justify-content-center gx-0">
          {sectionConfig?.list?.map((item) => (
            <div className="item col-6 col-md-2">
              <div className="for_whom_box">
                <div className="img-box">
                  <img src={item?.imageUrl} crossOrigin="anonymous" alt="" />
                </div>
                <div className="detail-box">
                  <h5>
                    {item?.label}
                  </h5>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>
  );
}

export default ForWhom;
