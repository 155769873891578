/* eslint-disable react/prop-types */
import ImageTextSection from './ImageTextSection';

function WhyChooseUs({ sectionConfig }) {
  return (
    <ImageTextSection sectionConfig={sectionConfig} />
  );
}

export default WhyChooseUs;
