/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './App.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Home from './components/Home';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import BlogsList from './components/BlogsList';
import Enquiry from './components/Enquiry';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';

function App() {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getAppTexts() {
      const fetchedData = await axios.get('/api/v1/applicationTexts');
      setData(fetchedData.data.body);
    }

    getAppTexts();
  }, []);
  return (
    <>
      {!data && <h1>Attadeepa</h1>}
      {data && (
        <div>
          <BrowserRouter>
            <Header headerConfig={data?.header} />
            <Routes>
              <Route path="/" element={<Home texts={data?.home} />} />
              <Route
                path="/about"
                element={<AboutPage aboutConfig={data?.aboutPage} />}
              />
              <Route
                path="/services"
                element={<ServicesPage serviceConfig={data?.services} />}
              />
              <Route path="/blogs" element={<BlogsList />} />
            </Routes>
          </BrowserRouter>
          <Enquiry enquiryConfig={data?.enquiryDetails} />
          <Contact contactConfig={data?.contact} />
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
