/* eslint-disable react/prop-types */
import ImageTextSection from './ImageTextSection';

function About({ sectionConfig }) {
  return (
    <ImageTextSection sectionConfig={sectionConfig} />
  );
}

export default About;
